import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/index/About"
import Media from "../components/index/Media"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Home" />

    <div className="bg-white">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-12">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 items-center">
          {/* Left */}
          <div
            id="ingress"
            className="flex-1 flex flex-col gap-4 lg:gap-8 text-center"
          >
            <div className="flex flex-col gap-4 text-xl max-w-3xl mx-auto">
              <Ingress />
            </div>
          </div>
          {/* Right */}
        </div>
      </div>
    </div>

    <div className="bg-stone-100">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-8 flex flex-col lg:flex-row gap-4 lg:gap-24 items-center">
        {/* Left */}
        <div className="w-1/2 lg:w-1/4">
          <div className="">
            <StaticImage
              src="../images/tubes.jpg"
              class="rounded-full opacity-[99%]"
            />
          </div>
        </div>
        {/* Right */}
        <div className="flex-1">
          <div className="flex flex-col gap-4 text-center lg:text-right">
            <h3 className="text-2xl lg:text-3xl uppercase font-bold">
              Simris Biologics
            </h3>
            <p>
              Identifying novel natural products and developing technology
              platforms to turns these into products that transform lives.
            </p>
            <div>
              <div className="inline-flex  group flex-row gap-2 items-center text-secondary">
                <div className="relative group-hover:translate-x-3 transition-all cursor-pointer ease-in-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 144 145"
                    style={{ minWidth: 18, width: 18, marginRight: "1rem" }}
                  >
                    <path d="M140.189 72.596a8.803 8.803 0 00-2.619-6.266L78.183 6.943c-3.446-3.446-9.088-3.447-12.527-.007-3.446 3.446-3.44 9.08 0 12.52L109.93 63.73l-90.764.002c-4.873 0-8.86 3.987-8.86 8.86.001 4.872 3.988 8.859 8.86 8.86l90.758.004-44.27 44.27c-3.445 3.446-3.439 9.08.001 12.52 3.446 3.446 9.081 3.44 12.52.001L137.57 78.85a8.772 8.772 0 002.619-6.254z"></path>
                  </svg>
                </div>
                <div>
                  <Link to="http://simrisbiologics.com">Visit </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="bg-slate-200">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-8 flex flex-col lg:flex-row gap-4 lg:gap-24 items-center">

        <div className="w-1/2 lg:w-1/4 lg:order-last">
          <div className="">
            <StaticImage
              src="../images/omega3-2.png"
              class="rounded-full opacity-[99%]"
            />
          </div>
        </div>
  
        <div className="flex-1">
          <div className="flex flex-col gap-4 text-center lg:text-left">
            <h3 className="text-2xl lg:text-3xl uppercase font-bold">
              Simris Nutraceuticals
            </h3>
            <p>
              Foods and food supplements inspired by microalgae and
              cyanobacteria.{" "}
            </p>
            <div>
              <div className="inline-flex  group flex-row gap-2 items-center text-secondary">
                <div className="relative group-hover:translate-x-3 transition-all cursor-pointer ease-in-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 144 145"
                    style={{ minWidth: 18, width: 18, marginRight: "1rem" }}
                  >
                    <path d="M140.189 72.596a8.803 8.803 0 00-2.619-6.266L78.183 6.943c-3.446-3.446-9.088-3.447-12.527-.007-3.446 3.446-3.44 9.08 0 12.52L109.93 63.73l-90.764.002c-4.873 0-8.86 3.987-8.86 8.86.001 4.872 3.988 8.859 8.86 8.86l90.758.004-44.27 44.27c-3.445 3.446-3.439 9.08.001 12.52 3.446 3.446 9.081 3.44 12.52.001L137.57 78.85a8.772 8.772 0 002.619-6.254z"></path>
                  </svg>
                </div>
                <div>
                  <Link to="https://eu.simris.com/en">Visit </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/* <About /> */}
    {/* <div className="bg-[#d6cec8]">
      <div className="py-16">
        <Media />
      </div>
    </div> */}
    {/* <div className="overflow-hidden">
      <StaticImage src="../images/ocean2.jpg" className="h-[75vh] scale-105" />
    </div> */}
  </Layout>
)

export default IndexPage

function Ingress() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 19 }) {
        content
        title
      }
    }
  `)
  const content = data.wpPage.content
  console.log(content)
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className="wp-content"
      />
    </>
  )
}
